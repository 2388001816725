import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from './util/axios'
import storage from './util/storage'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import store from './store'
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (!storage.get('token') && to.meta.login == 1) {
    storage.set('frompath', to.path)
    next({ path: "/login", replace: true })
  }
  next()
})

router.afterEach((to, from) => {

})

const goPage = function (url) {
  router.push(url);
}

const exportData = function (url, param, fileName) {
  // loadingInstance = Loading.service()
  axios({
    url: url,
    data: param,
    responseType: 'blob'
  }).then(res => {
    // loadingInstance.close()
    let blob = new Blob([res]);
    if ("download" in document.createElement("a")) {
      // 不是IE浏览器
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    } else {
      // IE 10+
      window.navigator.msSaveBlob(blob, fileName);
    }
  })
}

const saveInfo = function (infoName) {
  return new Promise((resolve) => {
    this.tempInfo = {}
    for (let item in this[infoName]) {
      this.tempInfo[item] = this[infoName][item];
    }
    console.log(this.tempInfo)
    resolve()
  })
}
const resetInfo = function (infoName) {
  for (let item in this[infoName]) {
    this[infoName][item] = this.tempInfo[item]
  }
}

const getTerminal = function () {
  if (
      /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
          navigator.userAgent
      )
  ) {
    return "mobile";
  } else {
    return "pc";
  }
}

const getCommonInfo = function (options) {
  return new Promise((resolve) => {
    axios({
      url: '/index/getCommonInfo',
      data: {
        info: options
      },
      showInfo:0
    }).then(res => {
      if (res.status == 1) {
        for (let item in options) {
          this[options[item]] = res[options[item]];
        }
      }
      resolve()
    })
  })
}

const getUploadUrl = function(url){
  return window.host+'/'+window.app+url
}

const pic = function(url){
  return url?this.picHost+url:''
}

const picList = function (arr,item){
  let temp = [];
  let url
  for(let i in arr){
    url = item?arr[i]:arr[i][item]
    if(url){
      temp.push(this.picHost+url);
    }
  }
  return temp;
}

const hideloading = function () {
  this.loading = false
}
const showloading = function (){
  this.loading = true
}

app.config.globalProperties.tempInfo = {}
app.config.globalProperties.terminal = getTerminal()
app.config.globalProperties.HOST = window.host
app.config.globalProperties.loading = false
app.config.globalProperties.formLabelWidth = '120px'
app.config.globalProperties.pagesizes = ['10', '20', '50', '100', '1000']
app.config.globalProperties.pagesize = 10
app.config.globalProperties.layout = getTerminal()=='pc'?'total, sizes, prev, pager, next, jumper':'prev, pager, next'

app.mixin({
  methods: {
    $axios: axios,
    $setStorage: storage.set,
    $getStorage: storage.get,
    $removeStorage: storage.remove,
    $clearStorage: storage.clear,
    $goPage: goPage,
    $exportData: exportData,
    $saveInfo: saveInfo,
    $resetInfo: resetInfo,
    $getCommonInfo: getCommonInfo,
    $getUploadUrl:getUploadUrl,
    $pic:pic,
    $picList:picList,
    $hideloading:hideloading,
    $showloading:showloading
  }
})


app.use(ElementPlus, {
  locale: zhCn,
})


app.use(router).mount('#app')
