import {createRouter, createWebHistory} from 'vue-router'

const Layout = () => import('../views/layout/index.vue')
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path:'/olddata',
        component: Layout,
        children: [
            {
                path: 'web',
                meta: {
                    title: '网站数据',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/olddata/web.vue')
            },{
                path: 'update',
                meta: {
                    title: '升级',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/olddata/update.vue')
            }
        ]
    },
    {
        path: '/employee',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '员工列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/employee/list.vue')
            },{
                path: 'group',
                meta: {
                    title: '员工组列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/employee/group.vue')
            },{
                path: 'menu',
                meta: {
                    title: '员工菜单',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/employee/menu.vue')
            }
        ]
    },
    {
        path: '/type',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '业务类型列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/type/list.vue')
            }
        ]
    },
    {
        path: '/department',
        component: Layout,
        children: [
            {
                path: 'list/:type',
                meta: {
                    title: '列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/department/list.vue')
            }
        ]
    },
    {
        path: '/operator',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '操作员列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/operator/list.vue')
            },{
                path: 'group',
                meta: {
                    title: '操作员组',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/operator/group.vue')
            }
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            { 
                path: 'list',
                meta: {
                    title: '参数设置',
                    login:1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/list')
            },
            { 
                path: 'banner',
                meta: {
                    title: 'banner设置',
                    login:1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/banner')
            },
            { 
                path: 'article/:type',
                meta: {
                    title: '用户协议/隐私政策',
                    login:1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/article')
            },
            {
                path: 'managefield',
                meta: {
                    title: '参数管理',
                    login:1 
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/managefield')
            },
            {
                path: 'params',
                meta: {
                    title: '平台参数',
                    login:1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/setting/params')
            },
        ]
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () => import(/* webpackChunkName:'second' */ '../views/index/login')
    },{
        path: '/menu',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '菜单列表',
                    login: 1
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/menu/list.vue')
            }
        ]
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../views/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
